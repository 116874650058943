html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
    padding: 0;
    margin: 0;
      font-family: 'Inter', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the root div takes up at least the height of the viewport */
}
